<template>
    <div>
        <el-button @click="dialogVisible = true">公告</el-button>
        <el-dialog :visible.sync="dialogVisible" title="选择图片">
            <select-image :multiple="false" :active="false"></select-image>
        </el-dialog>

        <Tabs></Tabs>
    </div>
</template>


<script>
import selectImage from '@/components/Images'

import Tabs from '@/components/Tabs/'



export default {
    data() {
        return {
            dialogVisible: false
        }
    },
    components: {
        selectImage,
        Tabs
    }
}
</script>