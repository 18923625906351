<template>
  <el-tabs v-model="activeName" v-loading="loading">

    <el-tab-pane v-for='(tab, index) in tabs' :key="index" :label="tab.title" :name="index + ''">
      <span slot="label"><i :class="tab.icon"></i>{{tab.title}}</span>

      <el-form ref="form" :model="tab.content" :label-width="tab.labelWidth">

        <el-form-item v-for='(item, itemIndex) in tab.content' :key="itemIndex" :label="item.label">
          <!-- input -->
          <el-input v-if="item.type === 'input'" v-model="item.value" size="small" :placeholder="item.placeholder" clearable></el-input>

          <!-- switch -->
          <el-switch
              v-if="item.type === 'switch'"
              v-model="item.value">
          </el-switch>

          <!-- textarea -->
          <el-input
              type="textarea"
              v-if="item.type === 'textarea'"
              :placeholder="item.placeholder"
              v-model="item.value">
          </el-input>

          <!-- multitypes -->
          <div v-if="item.type === 'multitypes'">
            <el-table :data="item.value" border>
              <el-table-column type="index" label="#"></el-table-column>

              <el-table-column v-for="(column, columnIndex) in item.types" :key="columnIndex" prop="date" :label="column.label" :width="column.width">
                <template slot-scope="scope">
                  <!-- input -->
                  <el-input v-if="column.type === 'input'" v-model="scope.row[column.name]" size="small" :disabled="column.disabled" :placeholder="column.placeholder" clearable></el-input>
                  <!-- code -->
                  <el-link :underline="false" v-if="column.type === 'code'" size="small">{{scope.row[column.name]}}</el-link>
                  <!-- switch -->
                  <el-switch
                      v-if="column.type === 'switch'"
                      v-model="scope.row[column.name]">
                  </el-switch>
                  <!-- uploader -->
                  <el-input v-if="column.type === 'uploader' && !column.showImage" v-model="scope.row[column.name]" size="small" :placeholder="column.placeholder" clearable>
                    <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload(item.name,scope.$index,column.name)"></el-button>
                  </el-input>
                  <!-- uploader showImage -->
                  <el-popover
                      v-if="column.type === 'uploader' && column.showImage"
                      popper-class="preview-img"
                      placement="right-start"
                      :disabled="!scope.row[column.name]"
                      trigger="hover">
                    <el-avatar :src="scope.row[column.name]" fit="cover"></el-avatar>
                    <el-input slot="reference" v-model="scope.row[column.name]" size="small" :placeholder="column.placeholder" clearable>
                      <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload(item.name,scope.$index,column.name)"></el-button>
                    </el-input>
                  </el-popover>

                  <!-- categorySelect 单选 -->
                  <el-select v-if="column.type === 'categorySelect'" v-model="scope.row[column.name]" placeholder="请选择">
                    <!--<el-option
                        v-for="item in column.gear_name"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>-->
                  </el-select>

                  <div class="group_input"  v-if="column.type === 'group_input'" >
                    <!--<el-input v-for="(list, listIndex) in tabs[0].content[1].value" placeholder="请输入内容" v-model="scope.row[column.name + listIndex]" size="mini" style="margin-bottom: 6px;">
                      <template slot="prepend">{{list.name}}</template>
                    </el-input>-->
                    <div class="group_input_item" v-for="(list, listIndex) in tabs[0].content[1].value">
                      <el-link :underline="false">{{list.name}}</el-link>
                      <el-input-number size="small" v-model="scope.row[column.name][listIndex]" :min="1" :max="100" :step="5"></el-input-number>
                    </div>
                  </div>

                </template>
              </el-table-column>

              <el-table-column
                  label="操作"
                  width="120">
                <template slot-scope="scope">
                  <!--<el-button type="info" plain icon="el-icon-arrow-up" size="mini"></el-button>
                  <el-button type="info" plain icon="el-icon-arrow-down" size="mini"></el-button>-->
                  <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="removeMultiTypes(item.name,scope.$index)">
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="addMultiTypes(item.name)">{{item.subLabel}}</el-button>
          </div>

          <div class="tab-decoration" v-if="item.decoration" >
            <el-tag type="warning">{{item.decoration}}</el-tag>
          </div>
        </el-form-item>
      </el-form>

      <el-row>
        <el-col :span="24">
          <el-button type="primary" style="float: right;" icon="el-icon-check" @click="save">保存全部</el-button>
        </el-col>
      </el-row>
    </el-tab-pane>

  </el-tabs>
</template>

<script>
export default {
  name: 'tabs',
  data() {
    return {
      loading: true,

      // element option
      activeName: '0',

      // select
      select: [],

      //options
      options: [],

      // 选项
      tabs: [
        {
          "title": "API",
          "icon": "el-icon-menu",
          "labelWidth": "130px",
          "content": [
            {
              "type": "multitypes",
              "label": "域名授权",
              "subLabel": "添加",
              "subLabelWidth": "100px",
              "displayAsTable": true,
              "types": [
                // {
                //     "type": "uploader",
                //     "showImage": true,
                //     "name": "image",
                //     "label": "图片"
                // },
                {
                  "type": "input",
                  "placeholder": "http://",
                  "name": "href",
                  "label": "链接地址"
                },
                {
                  "type": "input",
                  "placeholder": "请输入...",
                  "width": '280px',
                  "name": "title",
                  "label": "备注"
                }
              ],
              "value": [],
              "name": "authorization_api",
              "decoration": "",
              "gear_name": "",
              "gear_value": ""
            },
            {
              "type": "multitypes",
              "label": "代理等级",
              "subLabel": "添加",
              "subLabelWidth": "100px",
              "displayAsTable": true,
              "value": [],
              "name": "game_agent",
              "decoration": "",
              "gear_name": "",
              "gear_value": "",
              "types": [
                {
                  "type": "input",
                  "placeholder": "请输入...",
                  "name": "name",
                  "label": "等级名称"
                },
                {
                  "type": "input",
                  "placeholder": "请输入...",
                  "name": "csr",
                  "label": "有效月客数"
                },
                {
                  "type": "input",
                  "placeholder": "请输入...",
                  "name": "profit",
                  "label": "当月负盈利总额（万）"
                },
              ]
            },
            {
              "type": "multitypes",
              "label": "游戏佣金比例",
              "subLabel": "添加",
              "subLabelWidth": "100px",
              "displayAsTable": true,
              "value": [],
              "name": "game_bkge",
              "decoration": "",
              "gear_name": "",
              "gear_value": "",
              "types": [
                {
                  "type": "input",
                  "placeholder": "请输入...",
                  "name": "name",
                  "label": "游戏名称"
                },
                {
                  "type": "input",
                  "placeholder": "http://",
                  "name": "host",
                  "label": "host（*）"
                },
                {
                  "type": "group_input",
                  "placeholder": "请输入...",
                  "name": "cps",
                  "label": "提成比例（%）"
                },
              ]
            },
          ]
        },
        {
          "title": "统一登录",
          "icon": "el-icon-menu",
          "labelWidth": "130px",
          "content": [
            {
              "type": "multitypes",
              "label": "授权站点",
              "subLabel": "添加",
              "subLabelWidth": "100px",
              "displayAsTable": true,
              "types": [
                {
                  "type": "code",
                  "placeholder": "请输入...",
                  "name": "code",
                  "label": "code",
                  "disabled": true,
                },
                {
                  "type": "uploader",
                  "showImage": true,
                  "width": '200px',
                  "name": "logo",
                  "label": "站点Logo"
                },
                {
                  "type": "input",
                  "placeholder": "请输入...",
                  "name": "title",
                  "label": "站点名称"
                },
                {
                  "type": "input",
                  "placeholder": "http://",
                  "name": "url",
                  "label": "请求地址"
                },
                {
                  "type": "input",
                  "placeholder": "http://",
                  "name": "success",
                  "label": "回调地址"
                },
                {
                  "type": "input",
                  "placeholder": "http://",
                  "name": "error",
                  "label": "登录失败"
                },
                {
                  "type": "input",
                  "placeholder": "请输入...",
                  "width": '200px',
                  "name": "desc",
                  "label": "备注"
                }
              ],
              "value": [],
              "name": "OAuthLogin",
              "decoration": "",
              "gear_name": "",
              "gear_value": ""
            },
          ]
        }
      ]
    }
  },
  created() {
    this.get()
  },
  mounted() {

  },
  methods: {
    get() {
      this.loading = false
    },
    // 上传图片
    mediaUpload: function(name, index, typeName) {
      console.log('mediaUpload', name, index, typeName)
    },

    //
    addMultiTypes: function(name) {
      for (var i = 0; i < this.tabs.length; i++) {
        for (var j = 0; j < this.tabs[i].content.length; j++) {
          if (this.tabs[i].content[j].name == name) {
            var component = this.tabs[i].content[j];
            var newSubValue = {};
            for (var k = 0; k < component.types.length; k++) {
              if(component.types[k].type === 'group_input') {
                newSubValue[component.types[k].name] = [];
              } else {
                newSubValue[component.types[k].name] = '';
              }

            }

            console.log('component.value',component)
            component.value.push(newSubValue);
          }
        }
      };
    },

    removeMultiTypes: function(name, index) {
      for (var i = 0; i < this.tabs.length; i++) {
        for (var j = 0; j < this.tabs[i].content.length; j++) {
          if (this.tabs[i].content[j].name == name) {
            var component = this.tabs[i].content[j];
            component.value.splice(index, 1)
          }
        }
      };
    },
    removeMultiUpload: function(name, index) {
      for (var i = 0; i < this.tabs.length; i++) {
        for (var j = 0; j < this.tabs[i].content.length; j++) {
          if (this.tabs[i].content[j].name == name) {
            var component = this.tabs[i].content[j];
            component.value.splice(index, 1)
          }
        }
      };
    },
    removeAllMultiUpload: function(name) {
      for (var i = 0; i < this.tabs.length; i++) {
        for (var j = 0; j < this.tabs[i].content.length; j++) {
          if (this.tabs[i].content[j].name == name) {
            this.tabs[i].content[j].value = "";
          }
        }
      };
    },
  }
}
</script>